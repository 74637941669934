import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Tabs,
} from '@makeably/creativex-design-system';
import ChallengeModal from 'components/internal/review/ChallengeModal';
import {
  emptyState,
  filterProps,
  getNullableDimension,
  getUniqueArrayDimension,
} from 'components/internal/shared';
import FilterTags from 'components/molecules/FilterTags';
import ItemsFilter from 'components/molecules/ItemsFilter';
import ItemsTable from 'components/molecules/ItemsTable';
import { getNextIndex } from 'utilities/array';
import { saveFilterQuery } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import { generateItemButton } from 'utilities/itemElement';
import {
  filterItems,
  getValueOptions,
} from 'utilities/itemFilter';
import { summaryCsvInternalReviewChallengesPath } from 'utilities/routes';
import {
  getPage,
  getParams,
  setParam,
} from 'utilities/url';

const challengeProps = PropTypes.arrayOf(
  PropTypes.shape({
    brandNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    campaignStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
    companyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.number.isRequired,
    marketNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    timeRemaining: PropTypes.number.isRequired,
    userInfo: PropTypes.string.isRequired,
    auditAssetId: PropTypes.number,
    guidelineName: PropTypes.string,
  }),
);

const propTypes = {
  canEvaluate: PropTypes.bool.isRequired,
  canEvaluateEscalated: PropTypes.bool.isRequired,
  escalated: challengeProps.isRequired,
  pending: challengeProps.isRequired,
  reviewed: challengeProps.isRequired,
  initialFilterSelections: filterProps,
  initialTabValue: PropTypes.string,
};

const defaultProps = {
  initialFilterSelections: {},
  initialTabValue: 'pending',
};

const filterDimensions = [
  {
    label: 'Brand',
    value: 'brand',
  },
  {
    label: 'Campaign Status',
    value: 'campaignStatus',
  },
  {
    label: 'Company',
    value: 'company',
  },
  {
    label: 'Guideline',
    value: 'guideline',
  },
  {
    label: 'Market',
    value: 'market',
  },
  {
    label: 'User',
    value: 'user',
  },
];

const headers = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'auditAssetId',
    label: 'CreativeX ID',
  },
  {
    key: 'campaignStatus',
    label: 'Campaign Status',
  },
  {
    key: 'company',
    label: 'Company',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'timeRemaining',
    label: 'Time Remaining',
  },
];

const defaultSort = {
  key: 'auditAssetId',
  asc: true,
};

function getTabs(escalated, pending, reviewed) {
  return [
    {
      challenges: pending,
      label: `Pending (${pending.length})`,
      value: 'pending',
    },
    {
      challenges: escalated,
      label: `Escalated (${escalated.length})`,
      value: 'escalated',
    },
    {
      challenges: reviewed,
      label: `Recently Reviewed (${reviewed.length})`,
      value: 'reviewed',
    },
  ];
}

function getItems(challenges, onIdClick) {
  return challenges.map(({
    auditAssetId,
    brandNames,
    campaignStatuses,
    companyNames,
    guidelineName,
    id,
    marketNames,
    timeRemaining,
    userInfo,
  }) => ({
    auditAssetId: getNullableDimension(auditAssetId),
    brand: getUniqueArrayDimension(brandNames),
    campaignStatus: getUniqueArrayDimension(campaignStatuses),
    company: getUniqueArrayDimension(companyNames),
    guideline: getNullableDimension(guidelineName),
    id: {
      element: generateItemButton(id, () => onIdClick(id)),
      value: id,
    },
    market: getUniqueArrayDimension(marketNames),
    timeRemaining: { value: timeRemaining },
    user: { value: userInfo },
  }));
}

function Challenges({
  canEvaluate,
  canEvaluateEscalated,
  escalated: initialEscalated,
  initialFilterSelections,
  initialTabValue,
  pending: initialPending,
  reviewed: initialReviewed,
}) {
  const params = getParams(window);
  const [escalated, setEscalated] = useState(initialEscalated);
  const [pending, setPending] = useState(initialPending);
  const [reviewed, setReviewed] = useState(initialReviewed);
  const [selectedTabValue, setSelectedTabValue] = useState(initialTabValue);
  const [items, setItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);
  const [sort, setSort] = useState(defaultSort);
  const [page, setPage] = useState(getPage(params));
  const [evaluateId, setEvaluateId] = useState(null);
  const tabs = getTabs(escalated, pending, reviewed);
  const selectedTab = tabs.find(({ value }) => value === selectedTabValue);

  useEffect(() => {
    const allItems = getItems(selectedTab.challenges, setEvaluateId);
    const options = getValueOptions(filterDimensions, allItems);

    setItems(allItems);
    setFilterOptions(options);
  }, [selectedTab.challenges]);

  const filteredItems = useMemo(() => (
    filterItems(items, filterSelections)
  ), [items, filterSelections]);

  const sortedItems = useMemo(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    return filteredItems.slice().sort(byKeyDir);
  }, [filteredItems, sort]);

  const handleFilterSelect = async (value) => {
    setPage(1);
    setFilterSelections(value);

    if (Object.keys(value).length > 0) {
      const uuid = await saveFilterQuery(value);
      setParam('filter_uuid', uuid, params, window);
    } else {
      setParam('filter_uuid', '', params, window);
    }
  };

  const handleTabClick = async (value) => {
    setSelectedTabValue(value);
    handleFilterSelect({});

    setParam('tab', value, params, window);
  };

  const handleChallengeComplete = (id, wasEscalated, state) => {
    const index = sortedItems.findIndex((item) => item.id.value === id);
    const nextIndex = getNextIndex(index, sortedItems.length);

    if (state === 'pending') {
      const found = pending.find((record) => record.id === id);
      setPending((last) => last.filter((record) => record.id !== id));

      if (wasEscalated) {
        setEscalated((last) => [...last, found]);
      } else {
        setReviewed((last) => [...last, found]);
      }
    } else if (state === 'escalated') {
      const found = escalated.find((record) => record.id === id);
      setEscalated((last) => last.filter((record) => record.id !== id));

      setReviewed((last) => [...last, found]);
    }

    if (nextIndex >= 0) {
      setEvaluateId(sortedItems[nextIndex].id.value);
    } else {
      setEvaluateId(null);
      handleFilterSelect({});
    }
  };

  return (
    <>
      <Card className="u-flexColumn u-gap-24">
        <div className="u-flexRow u-justifyBetween u-gap-16">
          <div className="u-flexRow u-alignCenter u-gap-8">
            <ItemsFilter
              dimensions={filterDimensions}
              isOpen={filterOpen}
              options={filterOptions}
              selections={filterSelections}
              onClose={() => setFilterOpen(false)}
              onOpen={() => setFilterOpen(true)}
              onSelect={handleFilterSelect}
            />
            <FilterTags
              dimensions={filterDimensions}
              selections={filterSelections}
              onClick={() => setFilterOpen(true)}
              onRemove={handleFilterSelect}
            />
          </div>
          <Button
            label="Download Weekly Report CSV"
            url={summaryCsvInternalReviewChallengesPath()}
            variant="secondary"
          />
        </div>
        <div>
          <Tabs
            currentTab={selectedTab?.label}
            tabs={tabs.map((tab) => ({
              ...tab,
              onClick: () => handleTabClick(tab.value),
            }))}
            variant="button"
          />
          <ItemsTable
            className="u-scrollShadowRight"
            emptyTableContent={emptyState}
            headers={headers}
            items={sortedItems}
            page={page}
            sort={sort}
            onPageChange={setPage}
            onSortChange={setSort}
          />
        </div>
      </Card>
      <ChallengeModal
        canEvaluate={canEvaluate}
        canEvaluateEscalated={canEvaluateEscalated}
        id={evaluateId}
        onClose={() => setEvaluateId(null)}
        onComplete={handleChallengeComplete}
      />
    </>
  );
}

Challenges.propTypes = propTypes;
Challenges.defaultProps = defaultProps;

export default Challenges;
